var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('KioskSlide', {
    attrs: {
      "title": "Building Half Hourly Usage",
      "sub-title": "Half hourly usage at ".concat(_vm.kiosk.asset.siteName),
      "hide": _vm.hideSlide
    }
  }, [_c('div', {
    staticClass: "p-5 border-bottom"
  }, [_vm.loading || _vm.loadingAction.getConsumption ? _c('Spinner') : _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-5"
  }, [_c('div', {
    staticClass: "font-w600 font-size-h2"
  }, [_vm._v("Day Usage (Yesterday)")]), _c('div', {
    staticClass: "display-4 font-w700 mb-4"
  }, [_vm._v(_vm._s(_vm._f("numberFormat")(_vm.currentUsage)) + " "), _c('span', {
    staticClass: "font-w600 small"
  }, [_vm._v("kWh")])]), _c('PctDiff', {
    attrs: {
      "usage": _vm.currentUsage,
      "prev-usage": _vm.prevUsage
    }
  }, [_vm._v("Compared to same day last week")]), _c('div', {
    staticClass: "font-w600 font-size-h2"
  }, [_vm._v("Night Usage (Yesterday)")]), _c('div', {
    staticClass: "display-4 font-w700 mb-4"
  }, [_vm._v(_vm._s(_vm._f("numberFormat")(_vm.currentUsageNight)) + " "), _c('span', {
    staticClass: "font-w600 small"
  }, [_vm._v("kWh")])]), _c('PctDiff', {
    attrs: {
      "usage": _vm.currentUsageNight,
      "prev-usage": _vm.prevUsageNight
    }
  }, [_vm._v("Compared to same night last week")])], 1), _c('div', {
    staticClass: "col"
  }, [_c('AssetHalfHourlyConsumption', {
    staticStyle: {
      "height": "325px"
    },
    attrs: {
      "period": _vm.hhGraph.period,
      "data": _vm.consumption.currentHH.data,
      "units": "kWh"
    }
  })], 1)])], 1), _c('div', {
    staticClass: "p-5"
  }, [_vm.loading || _vm.loadingAction.getConsumption ? _c('Spinner') : _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-5"
  }, [_c('div', {
    staticClass: "font-w600 font-size-h2"
  }, [_vm._v("Past Week Usage Heatmap")]), _c('div', {
    staticClass: "display-4 font-w700 mb-4"
  }, [_vm._v(_vm._s(_vm._f("numberFormat")(_vm.currentWeekUsage)) + " "), _c('span', {
    staticClass: "font-w600 small"
  }, [_vm._v("kWh")])]), _c('PctDiff', {
    attrs: {
      "usage": _vm.currentWeekUsage,
      "prev-usage": _vm.prevWeekUsage
    }
  }, [_vm._v("Compared to previous week")])], 1), _c('div', {
    staticClass: "col"
  }, [_c('HourlyConsumptionHeatmap', {
    staticStyle: {
      "height": "350px"
    },
    attrs: {
      "period": _vm.heatMapGraph.period,
      "data": _vm.consumption.currentHH.data,
      "units": "kWh",
      "grid-height": "50%",
      "date-format": "ddd Do"
    }
  })], 1)])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }