<template>
  <KioskSlide title="Building Half Hourly Usage" :sub-title="`Half hourly usage at ${kiosk.asset.siteName}`" :hide="hideSlide">
    <div class="p-5 border-bottom">
      <Spinner v-if="loading || loadingAction.getConsumption" />
      <div v-else class="row">
        <div class="col-md-5">
          <div class="font-w600 font-size-h2">Day Usage (Yesterday)</div>
          <div class="display-4 font-w700 mb-4">{{ currentUsage | numberFormat }} <span class="font-w600 small">kWh</span></div>
          <PctDiff :usage="currentUsage" :prev-usage="prevUsage">Compared to same day last week</PctDiff>
          <div class="font-w600 font-size-h2">Night Usage (Yesterday)</div>
          <div class="display-4 font-w700 mb-4">{{ currentUsageNight | numberFormat }} <span class="font-w600 small">kWh</span></div>
          <PctDiff :usage="currentUsageNight" :prev-usage="prevUsageNight">Compared to same night last week</PctDiff>
        </div>
        <div class="col">
          <AssetHalfHourlyConsumption style="height: 325px" :period="hhGraph.period" :data="consumption.currentHH.data" units="kWh" />
        </div>
      </div>
    </div>
    <div class="p-5">
      <Spinner v-if="loading || loadingAction.getConsumption" />
      <div v-else class="row">
        <div class="col-md-5">
          <div class="font-w600 font-size-h2">Past Week Usage Heatmap</div>
          <div class="display-4 font-w700 mb-4">{{ currentWeekUsage | numberFormat }} <span class="font-w600 small">kWh</span></div>

          <PctDiff :usage="currentWeekUsage" :prev-usage="prevWeekUsage">Compared to previous week</PctDiff>
        </div>
        <div class="col">
          <HourlyConsumptionHeatmap
            style="height: 350px"
            :period="heatMapGraph.period"
            :data="consumption.currentHH.data"
            units="kWh"
            grid-height="50%"
            date-format="ddd Do"
          />
        </div>
      </div>
    </div>
  </KioskSlide>
</template>
<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import Spinner from '@/components/SpinnerLogo';
import KioskSlide from './KioskSlide';
import AssetHalfHourlyConsumption from '@/views/Machine/templates/charts/AssetHalfHourlyConsumption';
import HourlyConsumptionHeatmap from '@/views/Machine/templates/charts/HourlyConsumptionHeatmap';
import PctDiff from './components/PctDiff.vue';

export default {
  name: 'KioskEnergy',
  components: {
    AssetHalfHourlyConsumption,
    HourlyConsumptionHeatmap,
    KioskSlide,
    PctDiff,
    Spinner
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    hideSlide: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      yesterday: moment().subtract(2, 'days').startOf('day')
    };
  },
  computed: {
    ...mapGetters({
      kiosk: 'kioskLive/kiosk',
      asset: 'kioskLive/asset',
      consumption: 'kioskLive/consumption',
      loadingAction: 'kioskLive/loadingAction',
      loading: 'kioskLive/loading'
    }),
    hhGraph() {
      return {
        period: {
          startDate: moment().subtract(2, 'days').startOf('day'),
          endDate: moment().subtract(1, 'days').startOf('day')
        }
      };
    },
    heatMapGraph() {
      return {
        period: {
          heatMapStart: this.yesterday.clone().subtract(1, 'week'),
          heatMapEnd: this.yesterday
        }
      };
    },
    currentUsage() {
      const startDate = this.yesterday;

      const consumptionItems = this.consumption.currentHH.data.filter(item => moment(item.startDate).isSame(startDate, 'day'));

      return consumptionItems.reduce((acc, item) => acc + item.dayConsumption, 0);
    },
    currentUsageNight() {
      const startDate = this.yesterday;

      const consumptionItems = this.consumption.currentHH.data.filter(item => moment(item.startDate).isSame(startDate, 'day'));

      return consumptionItems.reduce((acc, item) => acc + item.nightConsumption, 0);
    },
    currentWeekUsage() {
      const startDate = this.yesterday.clone().subtract(1, 'week');
      const endDate = this.yesterday;

      const consumptionItems = this.consumption.currentHH.data.filter(item => moment(item.startDate).isBetween(startDate, endDate, 'day', '[]'));

      return consumptionItems.reduce((acc, item) => acc + item.consumption, 0);
    },
    prevUsage() {
      const startDate = this.yesterday.clone().subtract(1, 'week');

      const consumptionItems = this.consumption.currentHH.data.filter(item => moment(item.startDate).isSame(startDate, 'day'));

      return consumptionItems.reduce((acc, item) => acc + item.dayConsumption, 0);
    },
    prevUsageNight() {
      const startDate = this.yesterday.clone().subtract(1, 'week');

      const consumptionItems = this.consumption.currentHH.data.filter(item => moment(item.startDate).isSame(startDate, 'day'));

      return consumptionItems.reduce((acc, item) => acc + item.nightConsumption, 0);
    },
    prevWeekUsage() {
      const startDate = this.yesterday.clone().subtract(2, 'weeks');
      const endDate = this.yesterday.clone().subtract(1, 'week');

      const consumptionItems = this.consumption.currentHH.data.filter(item => moment(item.startDate).isBetween(startDate, endDate, 'day', '[]'));

      return consumptionItems.reduce((acc, item) => acc + item.consumption, 0);
    }
  },
  methods: {
    percentageDiff(usage, prevUsage) {
      if (prevUsage === 0 && usage !== 0) return 100;
      if (prevUsage === 0 && usage === 0) return 0;
      const pctDiff = (usage - prevUsage) / prevUsage;

      return Math.round(pctDiff * 100 * 10) / 10;
    }
  }
};
</script>
<style lang="scss"></style>
