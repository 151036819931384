<template>
  <Chart svg :option="options" />
</template>
<script>
import { merge } from 'lodash';
import moment from 'moment';
import Chart from '@/components/echarts/Chart';

import chartOptions from './options';

export default {
  components: {
    Chart
  },
  props: {
    period: {
      type: Object,
      required: true
    },
    data: {
      type: Array,
      required: true
    },
    units: {
      type: String,
      required: true
    }
  },
  computed: {
    options() {
      // Must be daily granularity data
      const hhArray = Array.from({ length: moment(this.period.endDate).diff(this.period.startDate, 'hours') * 2 }, (_, i) =>
        moment(this.period.startDate).add(i * 30, 'minutes')
      );

      const hhSeries = hhArray.map(date => {
        const dayData = this.data.filter(item => moment(item.startDate).isSame(date, 'minute'));
        return dayData.reduce((acc, item) => acc + item.consumption, 0);
      });

      const options = merge({}, chartOptions, {
        xAxis: {
          name: 'Time',
          type: 'category',
          data: hhArray.map(date => date.format('HH:mm')),
          axisLabel: {
            rotate: 45
          }
        },
        yAxis: {
          type: 'value',
          name: this.units,
          splitNumber: 2
        },
        series: {
          name: 'HH Series',
          type: 'bar',
          data: hhSeries,
          smooth: true,
          lineStyle: {
            width: 0
          },
          showSymbol: false,
          areaStyle: {
            opacity: 0.8
          },
          itemStyle: {
            borderRadius: [5, 5, 0, 0]
          }
        },
        legend: {
          show: false
        }
      });

      return options;
    }
  }
};
</script>
